@import url("@hey/image-cropper/styles.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply overflow-y-scroll;
}

body {
  @apply bg-gray-50;
  @apply dark:bg-black;
}

.shimmer {
  @apply animate-shimmer;
  @apply bg-gray-300;
  @apply dark:bg-gray-600;
}

.menu-item {
  @apply m-2 block cursor-pointer rounded-lg px-2 py-1.5 text-sm text-gray-700 dark:text-gray-200;
}

.linkify a {
  @apply text-brand-500;
  @apply hover:text-brand-600 dark:hover:text-brand-500;
}

.linkify-button button {
  @apply text-brand-500;
  @apply hover:text-brand-600 dark:hover:text-brand-500;
}

::-webkit-file-upload-button {
  @apply mr-2 cursor-pointer rounded-l-xl border-0 px-3 py-2;
  @apply bg-gray-200 dark:bg-gray-900;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.page-center {
  @apply flex h-[calc(100vh-65px)] items-center justify-center;
}

.label {
  @apply mb-1 font-medium text-gray-800 dark:text-gray-200;
}

.dropdown-active {
  @apply bg-gray-100 dark:bg-gray-800;
}

.focusable-dropdown-item[data-focused] {
  @apply bg-gray-200 dark:bg-gray-700;
}

.divider {
  @apply border-b dark:border-gray-700;
}

.ld-text-gray-500 {
  @apply text-gray-500 dark:text-gray-200;
}

.ProseMirror {
  & code {
    @apply rounded-lg bg-gray-300 px-[5px] py-[2px] text-sm dark:bg-gray-700;
  }

  & a,
  span[data-hashtag],
  span[data-cashtag],
  span[data-mention="profile"],
  span[data-mention="club"] {
    @apply text-brand-500;
  }

  & .prosekit-virtual-selection {
    box-shadow: none;
    background-color: transparent;
  }

  & .prosekit-placeholder::before {
    @apply opacity-50;
  }
}

.text-md {
  @apply !text-sm sm:!text-base;
}

.oembed-player,
iframe {
  @apply rounded-xl;
}

/** CSS overrides for plyr audio **/
.plyr__progress__buffer {
  @apply !bg-white;
}

.plyr--audio .plyr__controls {
  @apply !bg-transparent !text-white;
}

.plyr--audio .plyr__control {
  @apply hover:!bg-transparent;
}

.plyr--audio.plyr--full-ui input[type="range"] {
  @apply !text-white;
}

.pb-safe {
  padding-bottom: env(safe-area-inset-bottom);
}

#typeahead-menu {
  @apply z-20;
}

.rc-slider-rail {
  @apply bg-black dark:bg-white !important;
  @apply opacity-30;
}

.rc-slider-track {
  @apply bg-black dark:bg-white !important;
}

.rc-slider-handle {
  @apply border-black dark:border-white !important;
}

.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px rgb(139 92 246 / 0.3) !important;
}

/* Markup styles */
.markup > p,
ul {
  @apply leading-6 sm:leading-[26px];
  @apply [&:not(:last-child)]:mb-2;
}

.full-page-post-markup > p,
ul {
  @apply text-[15px] sm:text-[17px];
  @apply !leading-7 sm:leading-[26px];
  @apply [&:not(:last-child)]:mb-3 sm:[&:not(:last-child)]:mb-4;
}

.lp-player {
  @apply [&>div>div]:shadow-none;
  @apply [&>div>div]:border;
  @apply [&>div>div]:border-gray-100;
  @apply [&>div>div]:dark:border-gray-700;
}

.livepeer-aspect-ratio-container {
  @apply [&>div>img]:object-contain;
}

/* react-virtuoso - Virtual profile list */
.virtual-account-list {
  @apply !h-[80vh];
  @apply [&>div>div]:divide-y;
  @apply [&>div>div]:dark:divide-gray-700;
}

.virtual-divider-list-window {
  @apply [&>div>div]:divide-y;
  @apply [&>div>div]:dark:divide-gray-700;
}

/* react-virtuoso - Virtual notifications list */
.virtual-notification-list {
  @apply [&>div>div]:divide-y;
  @apply [&>div>div]:dark:divide-gray-700;
}

.pride-text {
  background: linear-gradient(
    to right,
    #dc2626,
    #ea580c,
    #ca8a04,
    #16a34a,
    #2563eb,
    #4f46e5,
    #7c3aed
  );
  @apply bg-clip-text text-transparent;
}

.horizontal-loader > div {
  background: linear-gradient(
    5deg,
    #dc2626,
    #ea580c,
    #ca8a04,
    #16a34a,
    #2563eb,
    #4f46e5,
    #7c3aed
  );
  width: 100%;
  height: 5px;
  @apply rounded-tl-xl rounded-tr-xl;
  animation: gradient 4s linear infinite;
  background-size: 250% 100%;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 500%;
  }
}

@keyframes pulse {
  50% {
    background-position: 0%;
  }
}
